<template>
  <v-container fluid fill-height justify="center">
    <v-row justify="center" class="pa-0 pt-6">
      <v-col cols="12" sm="10" md="8">
        <v-row class="pa-0">
          <v-col v-for="(child, index) in items" :key="index" cols="12" md="4" sm="6">
            <v-card elevation="10">
              <v-list-item three-line :to="{ name: 'Client', params: { client: child } }">
                <v-avatar rounded class="ma-3">
                  <v-icon size="50" color="#CAAC79">
                    {{ child.icon }}
                  </v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="pa-0" v-text="child.name" />
                  <v-list-item-subtitle class="pa-0">
                    {{ child.address }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { webserver, getIssueDate, getToday } from "../../services/webserver.js";

export default {
  components: {},
  mounted() {
    console.log(this.$store.state.profile);
    this.get_items();
  },
  data() {
    return {
      chosenPhoto: null,
      table: "clients",
      validMsg: false,
      broadcast: { email: null, subject: null, msg: null },
      broadcast_dialog: false,
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ header: 1 }, { header: 2 }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      issueDateFrom: getIssueDate(5),
      issueDateTo: getToday(),
      groupby: "",
      groups: [],
    };
  },
  methods: {
    newOrder(e) {
      this.$router.push({ name: "Inicio", params: e });
    },
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    updateLocation(e) {
      this.store.location = JSON.stringify(e);
    },
    changeClients() {
      this.get_items();
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        agent_code: this.$store.state.profile.code,
      };
      console.log(qry);
      this.loading_status = true;
      webserver("get_mytask", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.items = data;
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },

    getImagePhoto: function (account, src) {
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/photos/" +
        account +
        "/" +
        this.table +
        "/" +
        src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },

    call_contact(data) {
      window.location.href = "tel://" + data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
